import React, { useCallback, useEffect, useState } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useStaticQuery, graphql } from 'gatsby'
import moment from 'moment'
import Layout from "../components/layout"
import './blog.scss'
import trackEvent from "../analyticsHelpers"
import { Divider } from "../components/atoms/lines/Divider"
import RegularButton from "../components/atoms/buttons/RegularButton"
import { goToApp } from "../utils"
import Ellipse1 from '../images/ellipse-2.png'
import Ellipse2 from '../images/ellipse-specialist2.png'
import Ellipse3 from '../images/ellipse-specialist3.png'
import EllipseMob from '../images/ellipse-specialist-mob.png'
import Ellipse2Mob from '../images/ellipse-specialist2-mob.png'
import EspecialidadeImg from '../images/img-servicos.png'

const Blog = () => {
  useEffect(() => {
    trackEvent("sitenovo-blog-visualizou-tela")
  }, [])
  const data = useStaticQuery(graphql`
  query {
    allGhostPost(sort: { order: DESC, fields: [published_at] }) {
      edges {
        node {
          id
          slug
          title
          html
          published_at
          feature_image
          meta_title
          meta_description
          featured
          tags {
            id
            slug
          }
          primary_tag {
            id
            slug
          }
          authors {
            id
            slug
          }
        }
      }
    }
    allGhostTag(sort: {order: ASC, fields: name}) {
      edges {
        node {
            slug
            url
            postCount
        }
      }
    }
  }
`)
 
  function isJourneyContent(slug: string) {
    if (!slug) return false
    return slug.startsWith('gravidez-') || slug === 'pre-gestacao' || slug === 'pos-parto'
  }
  const ghostPosts = data.allGhostPost.edges.filter((post) => !isJourneyContent(post.node.primary_tag.slug))
  const ghostCategories = data.allGhostTag.edges.filter((tag) => !isJourneyContent(tag.node.slug))

  const postNode = ghostPosts.map(({ node }) => node)
  const firstFeaturedPost = postNode.filter((post) => {
    return post.featured === true
  }).shift()

  const featuredPosts = postNode.filter((post) => {
    return post.featured === true
  }).splice(1,2)

  const notFeaturedPosts = postNode.filter((post) => {
    return post.featured === false
  })
  const [maxRange, setMaxRange] = useState(4); 
  const loadMore = useCallback(() => {
    setMaxRange(prevRange => prevRange + 4)
  },[])

  const getCategories = (items) => {
    let categoryItems = items.map((item) => {
      return item.node.slug
    })
    let uniqueCategories = new Set(categoryItems)
    let categories = Array.from(uniqueCategories)
    categories = ["Todos os posts", ...categories]
    return categories
  }
  const [allCategories] = useState(getCategories(ghostCategories))
  const [blogPostItems, setBlogPostItems] = useState(notFeaturedPosts)
  const [selectedItem, setSelectedItem] = useState()
  const selectCategory = (category) => {
    if (category === "Todos os posts") {
      setBlogPostItems(notFeaturedPosts)
      setSelectedItem(category)
    } else {
      setBlogPostItems(notFeaturedPosts.filter((edge) => {
        return edge.tags.map((item) => item.slug).includes(category)
      }))
      setSelectedItem(category)
    }
  }
  
  return (
    <Layout>
      <GatsbySeo
        title='Theia | Blog'
        description='.'
        language='pt-br'
        canonical='https://blog.theia.com.br/'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/',
          title: 'Theia | Blog',
          description: 'Conteúdos sobre gravidez e saúde dos filhos, escritos por profissionais de saúde que você pode confiar',
          images: [
            {
              url: 'https://blog.theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Og Imagem Alt',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Theia | Blog'
        }, {
          name: 'dc:description',
          content: 'Conteúdos sobre gravidez e saúde dos filhos, escritos por profissionais de saúde que você pode confiar.'
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Conteúdos sobre gravidez e saúde dos filhos, escritos por profissionais de saúde que você pode confiar',
        }, {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, Fisioterapeuta Pelvica, psicologos, fisioterapeuta pelvico, preparador fisico, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, ginecologistas unimed,semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, dentista, atendimento odontológico na gestação, parto cesária',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
        ]}
      />
      <section className="wrapper-section-row blog">
        <h1 className="text-center text-primary text-dsTitle2 lg:text-dsTitle1 item1 mb-6">Blog da Theia</h1>
        <p className="item2 text-center text-dsTextMedium text-textSecondary">Acompanhe por aqui alguns dos conteúdos desenvolvidos pelo nosso time de especialistas em saúde</p>
      </section>

      <section className="wrapper-section-row posts">
        <div className="item1"> 
          <div className="relative w-full mb-12 mt-10 lg:mt-0">
            <h2 className="text-dsTitle2 text-primary inline-flex">Destaque</h2>
            <Divider
                maxWidth="50px"
                border="1px solid #582CC7"
                className="mt-2 mb-3"
              />
          </div>
          <div className="row-posts">
            <div key={firstFeaturedPost.id} className="mb-6 lg:mb-12 first-featured-post">
              <div className="image-holder-featured">
                <a href={`/${firstFeaturedPost.slug}`} onClick={() => trackEvent("sitenovo-blog-clicou-conteudo")}>
                  <img src={firstFeaturedPost.feature_image} alt="imagem destaque do blog" />
                </a>
              </div>
              <p className="text-textSmaller lg:text-textSmall text-left text-textSecondary mt-6">{moment(firstFeaturedPost.published_at).format("DD/MM/YYYY")} <span className="text-primary capitalize">- {firstFeaturedPost.tags[0].slug}</span></p>
              <Divider
                maxWidth="33px"
                border="1px solid #582CC7"
                className="mt-2 mb-3 first-featured-post-divider"
              />
              <h2 className="text-textPrimary font-medium text-textSmall lg:text-cardLarge post-title">
                <a href={`/${firstFeaturedPost.slug}`} onClick={() => trackEvent("sitenovo-blog-clicou-conteudo")}>
                  {firstFeaturedPost.title}
                </a>
              </h2>
            </div>
          </div>
        </div>

        <div className="item2"> 
          <div className="row-posts lg:pt-24">
          {featuredPosts.map((post) => {
            return (
              <>
                <div key={post.id} className="featured-posts">
                  <p className="text-textSmaller text-left text-textSecondary">{moment(post.published_at).format("DD/MM/YYYY")} <span className="text-primary capitalize">- {post.tags[0].slug}</span></p>
                  <Divider
                    maxWidth="33px"
                    border="1px solid #582CC7"
                    className="mt-2 mb-3"
                  />
                  <h2 className="text-textPrimary font-medium text-textSmall lg:text-textMedium post-title pb-4">
                    <a href={`/${post.slug}`} onClick={() => trackEvent("sitenovo-blog-clicou-conteudo")}>
                      {post.title}
                    </a>
                  </h2>
                </div>
              </>
              )
            })}
          </div>
        </div>
        <Divider
          maxWidth="100%"
          border="1px solid #EEEEEE"
          className="mt-2 mb-3 item5"
        />
        <div className="item3 mt-6 lg:mt-12"> 
          <div className="relative w-full mb-12 mt-10 lg:mt-0">
            <h2 className="text-dsTitle2 text-primary inline-flex">Últimos posts</h2>
            <Divider
              maxWidth="50px"
              border="1px solid #582CC7"
              className="mt-2 mb-3"
            />
          </div>
          <div className="row-posts ultimos-posts">
          {blogPostItems.slice(0, maxRange).map((node) => {
            return (
              <>
                {!node.featured &&
                  <div key={node.id} className="mb-12">
                    <div className="image-holder">
                      <a href={`/${node.slug}`} onClick={() => trackEvent("sitenovo-blog-clicou-conteudo")}>
                        <img src={node.feature_image} alt="imagem destaque do blog" />
                      </a>
                    </div>
                    <p className="text-textSmaller lg:text-textSmall text-left text-textSecondary mt-6">{moment(node.published_at).format("DD/MM/YYYY")} <span className="text-primary capitalize">- {node.tags[0].slug}</span></p>
                    <Divider
                      maxWidth="33px"
                      border="1px solid #582CC7"
                      className="mt-2 mb-3 first-featured-post-divider"
                    />
                    <h2 className="text-textPrimary font-medium text-textSmall lg:text-titleMedium post-title">
                      <a href={`/${node.slug}`} onClick={() => trackEvent("sitenovo-blog-clicou-conteudo")}>
                        {node.title}
                      </a>
                    </h2>
                  </div>
                }
              </>
              )
          })}
          </div>
          <div className="text-center w-full">
            {blogPostItems.length > 4 &&
             <RegularButton
              onClick={loadMore}
              label="Carregar mais posts"
              variant="secondary"
              extraClass="lg:w-2/4 lg:mx-auto load-more"
            />
            }
          </div>
        </div>
        <div className="item4 mt-6 lg:mt-12"> 
          <div className="relative w-full mb-12 mt-10 lg:mt-0">
            <h2 className="text-dsTitle2 text-primary inline-flex">Categorias</h2>
            <Divider
              maxWidth="50px"
              border="1px solid #582CC7"
              className="mt-2 mb-3"
            />
          </div>
          <ul className="bg-primary text-white list-categories">
            {allCategories.map((node) => {
              return (
              <li onClick={() => selectCategory(node)} className={selectedItem === node ? 'underline' : ''}>{node}</li>
              )
            })}
          </ul>
        </div>
      </section>
      <section className="relative especialidade-content grid-wrapper-section items-center lg:items-start overflow-hidden blog">
        <img src={Ellipse3} alt="" className="ellipse3" />
        <div className="item1">
          <h2 className="text-titleMedium lg:text-dsTitle1 text-primary main-title text-center lg:text-left mb-6">
            Profissionais experientes, cuidando de você de forma completa
          </h2>
          <RegularButton
            onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-blog-clicou-botao-comece-programa')}
            label="Comece seu programa"
          />
        </div>
        <div className="item2 self-end internas relative">
          <img src={Ellipse1} alt="" className="ellipse1 hidden md:block servicos-ellipse1" />
          <img src={Ellipse2} alt="" className="ellipse2 hidden md:block servicos-ellipse2" />
          <img src={EllipseMob} alt="" className="ellipse5 block md:hidden" />
          <img src={Ellipse2Mob} alt="" className="ellipse4 block md:hidden" />
          <img src={EspecialidadeImg} alt="" style={{ width: '540px', float: 'right' }} className="relative main-specialty-img" />
        </div>
      </section>
    </Layout>
  )
}

export default Blog